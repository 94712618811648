import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

import { orange, white } from 'src/styles/colors'

export const Section = styled.section`
  min-height: 348px;

  @media ${device.desktopXL} {
    min-height: 400px;
  }

  .bg-text {
    background: ${orange.extra};
    color: ${white};
    width: fit-content;
  }

  .scroll-link {
    bottom: 20px;
    fill: orange;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }

  p {
    margin-top: 23px;
    @media ${device.tablet} {
      margin-top: 30px;
    }

    @media ${device.desktopLG} {
      margin-top: 35px;
    }
  }
`
